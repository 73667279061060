<template>
  <div v-if="$ability.can('read', this.$route.meta.resource)">
    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mb-2">
        <div class="m-2">
          <b-row>
            <b-col>
              <b-form-group label-cols="2" label-cols-lg="2" label="Import File :" label-for="input-lg">
                <b-form-file ref="uploadFileJson" accept=".json, .csv" class="mb-2" @change="updateFiles" />
              </b-form-group>
            </b-col>

          </b-row>
          <div class="d-flex align-items-center justify-content-between">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" @click="clearData">
              <feather-icon icon="RefreshIcon" class="mr-50" />
              <span class="align-middle">{{ $t('Reset') }}</span>
            </b-button>
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="onExport">
              <feather-icon icon="RefreshIcon" class="mr-50" />
              <span class="align-middle">Export Exel Tempalte</span>
            </b-button>
          </div>

        </div>
      </b-card>
    </b-overlay>
    <div v-if="valueProduct != 0">
      <label>{{ $t('Product') }} {{ valueProduct }} / {{ maxProduct }} ({{
        nameProduct
      }})</label>
      <b-progress :max="maxProduct">
        <b-progress-bar :value="valueProduct">
          <strong>{{ valueProduct }} / {{ maxProduct }}</strong>
        </b-progress-bar>
      </b-progress>
    </div>

    <b-overlay :show="show" rounded="sm">
      <b-card no-body class="mb-0">
        <div class="m-2">
          <b-row>
            <b-col>
              <label>{{ $t('Data') }} {{ queriedItems }} {{ $t('List') }}</label>
              <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                :clearable="false" class="per-page-selector v-select-custom" @input="pageSize" />
            </b-col>

            <b-col cols="12" md="6" class="b-col-custom">
              <div class="d-flex align-items-center justify-content-end">
                <!-- <b-form-input
                    v-model="searchQuery"
                    class="d-inline-block mr-1"
                    :placeholder="$t('Search')+'...'"
                  /> -->
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="d-flex"
                  @click="selectAllRows">
                  <feather-icon icon="ListIcon" />
                  <span class="mr-25 align-middle">{{ $t('SELECT_ALL') }}</span>
                </b-button>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="warning" class="d-flex ml-2"
                  @click="clearSelected">
                  <feather-icon icon="XIcon" />
                  <span class="mr-25 align-middle">{{
                    $t('CLEAR_SELECTED')
                  }}</span>
                </b-button>

                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="d-flex ml-2"
                  @click="importData">
                  <feather-icon icon="PlusIcon" />
                  <span class="mr-25 align-middle">{{ $t('IMPORT') }}</span>
                </b-button>
                <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" class="d-flex ml-2"
                  @click="importAllProduct">
                  <span class="mr-25 align-middle">{{ $t('IMPORT_ALL') }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-table ref="selectableTable" striped responsive class="position-relative" :items="respData"
          :select-mode="selectMode" :fields="tableColumns" :per-page="perPage" :current-page="currentPage"
          primary-key="CF_BARCODE" show-empty :empty-text="$t('No matching records found')" selectable
          style="height: 60vh;" @row-selected="onRowSelected">
          <!-- Example scoped slot for select state illustrative purposes -->
          <template #cell(selected)="{ rowSelected }">
            <template v-if="rowSelected">
              <span aria-hidden="true">&check;</span>
              <span class="sr-only">Selected</span>
            </template>
            <template v-else>
              <span aria-hidden="true">&nbsp;</span>
              <span class="sr-only">Not selected</span>
            </template>
          </template>
          <!-- Column: Images -->
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col cols="12" sm="6" class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-start
                ">
              <span class="text-muted">{{ currentPage * perPage - (perPage - 1) }} -
                {{
                  queriedItems - currentPage * perPage > 0
                  ? currentPage * perPage
                  : queriedItems
                }}
                {{ $t('of') }} {{ queriedItems }}</span>
            </b-col>
          </b-row>
        </div>
        <b-col cols="12">
          <b-pagination v-model="currentPage" :total-rows="queriedItems" :per-page="perPage" first-number last-number
            class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @input="nextPage">
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </b-col>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BImg,
  BPagination,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
  BFormGroup,
  BFormFile,
  BButton,
  BTable,
  BOverlay,
  BModal,
  VBModal,
  BProgress,
  BProgressBar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
// import { useToast } from 'vue-toastification/composition'
import perPageOptions from '@/perPageOptions'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/warehouseProduct'
import axios from 'axios'
import * as XLSX from 'xlsx-color'

// const https = require('https')

const STORE_MODULE_NAME = 'warehouseProduct'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BImg,
    BPagination,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormGroup,
    BFormFile,
    BButton,
    // Sidebar,
    BTable,
    BOverlay,
    BModal,
    BProgress,
    BProgressBar,
    // BFormFile,
    // BBadge,
    // BLink,
    // BMedia,
    // BAvatar,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      avatarText,
      respData: [],
      perPageOptions,
      show: false,
      queriedItems: 0,
      currentPage: 1,
      perPage: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 50,
      selectMode: 'multi',
      selected: [],
      nameProduct: '',
      valueProduct: 0,
      maxProduct: 0,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'selected', label: this.$t('SELECTED'), sortable: false },
        {
          key: 'CF_BARCODE',
          label: `${this.$t('Bar Code')} (CF_BARCODE)`,
          sortable: false,
        },
        {
          key: 'CF_ITEMID',
          label: `${this.$t('Code')} (CF_ITEMID)`,
          sortable: false,
        },
        {
          key: 'CF_ITEMNAME',
          label: `${this.$t('NAME')} (CF_ITEMNAME)`,
          sortable: false,
        },
        {
          key: 'CF_FMLPRICE',
          label: `${this.$t('Price')} (CF_FMLPRICE)`,
          sortable: false,
        },
      ]
    },
    wsClient() {
      return store.state.websockets.wsClient[STORE_MODULE_NAME]
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, storeModule)
    }
    const userData = JSON.parse(localStorage.getItem('userData'))
    const applicationId = localStorage.getItem('applicationId')
    // WebSocket Connect
    const pathname = `/Products?type=${userData.userType}&userId=${userData.userId}&applicationId=${applicationId}`
    const params = {
      pathname,
      page: STORE_MODULE_NAME,
    }
    store.dispatch('websockets/connect', params)
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
    store.dispatch('websockets/disconnect', STORE_MODULE_NAME)
  },
  beforeMount() {
    // WebSocket
    if (this.wsClient) {
      // ถ้าเกิดเชื่อมไม่ได้ จะเข้า onErrorก่อน หลังจากนั้นจะทำการ onClose เอง
      this.wsClient.onerror = err => {
        console.log('onError')
        console.log(err)
      }
      this.wsClient.onclose = err => {
        console.log('onClose')
        console.log(err)
      }
      this.wsClient.onmessage = msg => {
        const SocketMessage = JSON.parse(msg.data)

        if (SocketMessage.data.send) {
          if (
            SocketMessage.data.send.data.message
            === 'IMPORT_PRODUCT_WAREHOUSE_IMAGE'
          ) {
            this.show = true
            this.nameProduct = SocketMessage.data.send.data.data.nameProduct
            this.valueProduct = SocketMessage.data.send.data.data.currentProduct
            this.maxProduct = SocketMessage.data.send.data.data.maxProduct
            if (this.valueProduct === this.maxProduct) {
              this.show = false
            }
          }
        }
      }
    }
  },
  methods: {
    onExport() {
      const dataWS = XLSX.utils.json_to_sheet([
        {
          CF_ITEMID: '1', CF_BARCODE: '00001', CF_ITEMNAME: 'Product name', CF_FMLPRICE: '1000',
        },
      ])
      const wb = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(wb, dataWS)
      XLSX.writeFile(wb, 'template_warehouse_product.xlsx')
    },
    selectAllRows() {
      this.$refs.selectableTable.selectAllRows()
      this.selected = this.respData
    },
    clearSelected() {
      this.$refs.selectableTable.clearSelected()
      this.selected = []
    },
    onRowSelected(items) {
      this.selected = items
    },
    clearData() {
      this.currentPage = 1
      this.respData = []
      this.selected = []
      this.queriedItems = 0
      this.$refs.uploadFileJson.reset()
    },
    pageSize(size) {
      localStorage.setItem('itemsPerPage', size)
      this.perPage = size
    },
    nextPage(page) {
      this.currentPage = page
    },
    updateFiles(input) {
      if (input.target.files && input.target.files[0]) {
        // const extension = input.target.files[0].name
        //   .split('.')
        //   .pop()
        //   .toLowerCase() // file extension from input file
        // const isSuccess = fileTypes.indexOf(extension) > -1
        // console.log(extension)
        const arrName = input.target.files[0].name.split('.')
        const ext = arrName[arrName.length - 1]

        const reader = new FileReader()
        reader.onload = e => {
          if (ext === 'xlsx' || ext === 'xls') {
            this.excelImport(e.target.result)
          } else if (ext === 'csv') {
            this.csvImport(e.target.result)
          }
        }
        reader.readAsText(input.target.files[0])
      }
    },
    csvImport(input) {
      const lines = input.split('\n')
      const header = lines[0].split(',')
      const output = lines.slice(1).map(line => {
        const fields = line.split(',')
        return Object.fromEntries(header.map((h, i) => [h, fields[i]]))
      })
      this.respData = output
      this.queriedItems = output.length
    },
    excelImport(data) {
      const workbook = XLSX.read(data, { type: 'buffer' })
      console.log(workbook)

      const json = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
      )
    },
    importAllProduct() {
      this.$bvModal
        .msgBoxConfirm(
          `${this.$t('Do you want to import all product')} ${this.respData.length
          } ${this.$t('List')}?`,
          {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'success',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          },
        )
        .then(value => {
          if (value === true) {
            if (this.respData.length > 0) {
              const obj = {
                dataSelected: this.respData,
              }
              this.show = true
              store
                .dispatch(`${STORE_MODULE_NAME}/import`, obj)
                .then(() => {
                  this.show = false

                  this.showToast(
                    'warning',
                    'top-right',
                    `${this.$t('IMPORT_DATA')}! ${this.respData.length
                    } ${this.$t('List')}`,
                    'PlusIcon',
                    this.$t('IMPORT_DATA'),
                  )
                })
                .catch(error => {
                  this.show = false

                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            }
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    importData() {
      this.$bvModal
        .msgBoxConfirm(
          `${this.$t('Do you want to import all product')} ${this.selected.length
          } ${this.$t('List')}?`,
          {
            title: this.$t('Please Confirm'),
            cancelVariant: 'outline-secondary',
            okVariant: 'success',
            okTitle: this.$t('Yes'),
            cancelTitle: this.$t('No'),
            centered: true,
          },
        )
        .then(value => {
          if (value === true) {
            if (this.selected.length > 0) {
              const obj = {
                dataSelected: this.selected,
              }
              this.show = true
              store
                .dispatch(`${STORE_MODULE_NAME}/import`, obj)
                .then(() => {
                  this.show = false

                  this.showToast(
                    'warning',
                    'top-right',
                    `${this.$t('IMPORT_DATA')}!`,
                    'PlusIcon',
                    this.$t('IMPORT_DATA'),
                  )
                })
                .catch(error => {
                  this.show = false

                  this.$toast({
                    component: ToastificationContent,
                    position: 'bottom-right',
                    props: {
                      title: this.$t('Error'),
                      icon: 'ErrorIcon',
                      variant: 'danger',
                      text: this.$t(error.response.data.message),
                    },
                  })
                })
            } else {
              this.showToast(
                'danger',
                'top-right',
                `${this.$t('NOT_SELECT_DATA')}!`,
                'XIcon',
                this.$t('NOT_SELECT_DATA'),
              )
            }
          }
          //  else {
          //   this.showToast(
          //     'warning',
          //     'top-right',
          //     `${this.$t('DELETE_CANCEL')}!`,
          //     'AlertCircleIcon',
          //     this.$t('DELETE_CANCEL'),
          //   )
          // }
        })
    },
    showToast(variant, position, text, icon, title) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title,
            icon,
            text,
            // icon: 'InfoIcon',
            // text: 'I do not think that word means what you think it means.',
            variant,
          },
        },
        {
          position,
        },
      )
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
